import React from 'react';
import { cleanInput } from "../../helpers/input";
import style from './style.module.css';

export default class InputDisabled extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: cleanInput(props.input.value)
        }
    }

    componentDidUpdate(prevProps) {
        const { input: { value } } = this.props;
        if (value !== prevProps.input.value && value) {
            this.setState({
                value: cleanInput(value)
            })
        }
    }

    parsedValue = (value) => {
        let result = [];
        if (value && value !== "") {
            JSON.parse(value).forEach(e => result.push(e.value));
        }
        return result.join(', ');
    }

    render() {
        const { label, required, disabled = false, border = false, toParse = false } = this.props;
        const { value } = this.state;
        return (
            <div className={`${style.formGroup}${border === false ? " " + style.inputDisplay : ""}${disabled === true ? " " + style.selectDisabled : ""}`}>
                <div className={`${style.controlLabel}`}>
                    {
                        required && (
                            <span className={style.required}>* </span>
                        )
                    }
                    <span>{label}</span>
                </div>
                <div className={style.textbox}>{toParse ? this.parsedValue(value) : value} &nbsp;</div>
            </div>
        )
    }
}