import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { get } from 'lodash';

import { ctapopupSignup, submitReset } from "../../actions";
import { Button, Checkbox, Input } from '../Field';
import { emailValidatorRegex } from '../../helpers/validator';
import { showToast } from '../../helpers/notify';

import style from './style.module.css';

const Form = ({ invalid, handleSubmit, onSubmit, bottomText, privacyText, submission, changeStep, submitReset }) => {
    const { fetching, status = 0, serverError } = submission;

    useEffect(() => {
        if (fetching === false && status === 200) {
            changeStep("thank-you");
            submitReset();
        }
        else if (fetching === false && status === 400) {
            showToast(serverError.error)
        }
    }, [fetching, status, serverError]);

    return (
        <form className={style.formWrap} onSubmit={handleSubmit(onSubmit)}>
            <div className={style.field}>
                <Field
                    name="email"
                    validate={[required(), ...emailValidatorRegex()]}
                    component={Input}
                    label="Email Address*"
                    placeholder="Enter email address"
                    fieldClass={style.input}
                    serverError={get(serverError, 'server_error.email')}
                />
            </div>
            <div className={style.field}>
                <Field
                    name="agree"
                    id="agree"
                    component={Checkbox}
                    checkValue={1}
                    label={get(privacyText, 'label')}
                    link_text="Privacy Policy."
                    link={get(privacyText, 'url')}
                    removeStarDotFromLink={true}
                    className={style.checkbox}
                />
            </div>
            <div className={style.action}>
                <Button
                    label="Submit"
                    submit={true}
                    disabled={invalid}
                    className={style.submit}
                    fetching={fetching}
                    color="btnColor"
                />
            </div>
            {bottomText && <div className={style.infoText} dangerouslySetInnerHTML={{ __html: bottomText }}></div>}
        </form>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        formValues: getFormValues("CTASignup")(state),
        initialValues: {
            agree: 0,
            popup: get(ownProps, 'id'),
            frompage: window.location.href,
        },
        submission: get(state, "submit.ctapopup", {}),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (data) => {
            dispatch(ctapopupSignup({ ...data, agree: !get(data, 'agree') ? 0 : 1 }));
        },
        submitReset: () => {
            dispatch(submitReset("ctapopup"))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'CTASignup',
    enableReinitialize: true
})(Form));