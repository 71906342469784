import React, { useEffect, useRef } from "react";
import { Field } from "redux-form";
import { required } from "redux-form-validators";

import Link from "../Link";
import { Checkbox } from "../Field";
import SVG from "../Media/Svg";
import { BackArrow } from "../../helpers/Icons";

import style from "./style.module.css";

function Item({ name, title, desc, fieldRequired = false, isActive, onClick, callBack }) {

    const descRef = useRef(null);

    useEffect(() => {
        if (descRef.current) {
            const ele = descRef.current
            if (isActive === true) {
                ele.style.height = `${ele.scrollHeight}px`;
                ele.style.opacity = `1`;
                ele.style.margin = `8px 40px`;
            } else {
                ele.removeAttribute("style");
            }
        }
    }, [isActive]);

    const clickHandler = () => {
        onClick();
    };

    return (<>
        <li className={`${style.item}${isActive === true ? ` ${style.toggled}` : ""}`}>
            <div className={style.head}>
                {
                    name === "non-essential-cookies"  ? 
                        <Checkbox  id={name} label={``} input={{value: name}} meta={{}} checkValue={name}  disabled/> 
                        :   <Field name={name === "non-essential-cookies" ? "preselect" : "option"} id={name} component={Checkbox} callBack={callBack} validate={fieldRequired === true ? [required()] : []} checkValue={name} label={``} />
                }
                <Link className={style.button} onClick={clickHandler}>
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                    <SVG className={style.arrow} src={BackArrow} />
                </Link>
            </div>
            <div ref={descRef} className={style.desc} dangerouslySetInnerHTML={{ __html: desc }} />
        </li>
    </>)
}

export default Item;