import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import $ from 'jquery-slim'

const Light = () => {
    useEffect(() => {
        $("html").removeClass(`darkTheme`).addClass(`lightTheme`)
    }, [])
    return (
        <>
            <Outlet context={{ height: 0, winWidth: window.innerWidth, winHeight: window.innerHeight }} />
        </>
    )
}

export default Light;