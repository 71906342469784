import React from 'react';
import style from './style.module.css';
import { cleanInput } from '../../helpers/input';

class RadioButtonGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false,
            value: cleanInput(props.input.value)
        };
    }

    static defaultProps = {
        label: '',
        required: false,
        options: {},
        cols: 1,
        withFlags: false,
        countries: [],
        targetObj: [],
        targetObjAttr: ''
    }

    onFocusHandler = () => {
        this.setState({
            focus: true
        })
    }

    onBlurHandler = () => {
        this.setState({
            focus: false
        })
    }

    onChangeHandler = (e) => {
        const value = cleanInput(e.target.value);
        const { input: { onChange } } = this.props;
        onChange(value);
        this.setState({
            value: value
        })
    }

    wrapperClass = (option = "") => {
        const { inputClass = "" } = this.props;
        const { value } = this.state;
        let className = style.checkbox
        if (inputClass !== "")
            className += ` ${inputClass}_${option.replaceAll(" ", "").toLowerCase()}`;
        if (value === option)
            className += ` ${style.checked}`;
        return className;
    }

    render() {
        const { label, required, serverError = false, options, input, meta: { error, touched } } = this.props;
        const { value, focus } = this.state;
        return (
            <div className={`${style.formGroup}${touched && error ? " " + style.hasError : ""}`}>
                {
                    label !== false && (
                        <div className={`${style.controlLabel}${focus || value ? " " + style.focus : ""}`}>
                            <span>{label}</span>
                            {
                                required && (
                                    <span className={style.required}>*</span>
                                )
                            }
                        </div>
                    )
                }
                <div className={`${style.checkboxGroup}`}>
                    {Object.entries(options).map(([key, option]) => {
                        return (
                            (
                                <div className={`${this.wrapperClass(option)}`} key={`${input.name}_key_${key}`}>
                                    <input
                                        type="radio"
                                        id={`${input.name}_${key}`}
                                        name={input.name}
                                        value={option}
                                        className={`${style.checkboxInput}`}
                                        checked={value === option}
                                        onChange={this.onChangeHandler}
                                    />
                                    <label className={style.checkboxLabel} htmlFor={`${input.name}_${key}`}>
                                        <span className={style.checkboxSelectorWrap} />
                                        <span dangerouslySetInnerHTML={{ __html: option }} />
                                    </label>
                                </div>
                            )
                        )
                    })}
                </div>
                {
                    touched && error && (
                        <p className={style.helpBlock}>{error}</p>
                    )
                }
                {
                    !error && serverError && (
                        <p className={style.helpBlock}>{serverError}</p>
                    )
                }
            </div>
        )
    }
}

export default RadioButtonGroup;