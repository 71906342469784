import React, { Component } from 'react';
import { get } from "lodash";

import style from './style.module.css';
import Link from '../Link';

export default class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.checkValue === props.input.value
        }
    }

    componentDidUpdate(prevProps) {
        const { input, checkValue } = this.props;
        if (get(prevProps, "input.value") !== input.value) {
            this.setState({
                checked: checkValue === input.value
            })
        }
    }

    render() {
        const { id, label, input, meta: { error, touched }, checkValue, className = false, checkBoxClass = false, link = false, removeStarDotFromLink = false, disabled = false } = this.props;
        let { link_text = "" } = this.props;
        let rTxt = "";
        if (removeStarDotFromLink && (link_text.indexOf('*') !== -1 || link_text.indexOf('.') !== -1)) {
            rTxt = link_text.substr(link_text.length - 1, 1);
            link_text = link_text.slice(0, -1);
        }
        return (
            <label htmlFor={id} className={`${style.checkbox}${className === false ? "" : " " + className}${touched && error ? " " + style.checkError : ""}`}>
                <input
                    type="checkbox"
                    onChange={this.onChangeHandler}
                    checked={input.value === checkValue}
                    className={style.checkboxInput}
                    id={id}
                    disabled={disabled}
                />
                <div className={`${style.checkboxSquare}${checkBoxClass ? ` ${checkBoxClass}` : ''}`}></div>
                {
                    label !== false && <div className={`${style.checkboxText}`}>
                        <span dangerouslySetInnerHTML={{ __html: label }} /> {link !== false && (<><Link to={link}>{link_text}</Link>{rTxt && <span dangerouslySetInnerHTML={{ __html: rTxt }} />}</>)}
                    </div>
                }
            </label>
        )
    }

    onChangeHandler = () => {
        const { input: { onChange }, checkValue } = this.props;
        const { checked } = this.state;
        onChange(checked ? false : checkValue);
        this.setState({
            checked: !checked
        })
    }
}