import { get } from "lodash";
import api from "./api";

const urls = {
  register: "/profile",
  verify_otp: "/user/verify",
  resend_otp: "/user/otp-resend",
  login: "/users/login",
  forgot: "/profile/forgot-password",
  reset: "/profile/reset-password",
  verifyHash: "/profile/verify-hash",
  config: "/config",
  articlecarousel: "/articles/info",
  pageInfo: "page",
  rate: "actions/rating",
  save: "actions/save",
  search: "articles",
  saveList: "saved-list",
  watchList: "watch-history",
  latestEdition: "latest-editions",
  watchListAnonymous: "watch-anonymous-history",
}

function generateURL(attr) {
  return `${get(urls, attr, attr)}/`;
}

export function nextSubmit(data) {
  return dispatch => {
    dispatch({
      type: "FORM_CONTINUE",
      payload: data
    });
  };
}
export function submitForm(attribute, data, patch = false) {
  let url = generateURL(attribute)
  return (dispatch) => {
    dispatch({
      type: "SUBMIT",
      payload: patch ? api.patch(url, data) : api.post(url, data),
      meta: { attribute, formData: data }
    });
  };
}
export function saveToken(data, userType, remember = true) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_TOKEN",
      payload: { data, userType },
      meta: { remember: remember }
    });
  };
}

export function saveUser(data) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_USER",
      payload: { data }
    });
  };
}

export function login(data, attribute = "login") {
  return dispatch => {
    dispatch({
      type: "LOGIN",
      payload: api.post('login/', data),
      meta: { attribute }
    })
  }
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
      payload: api.patch('/logout/')
    });
  };
}
export function getUser() {
  return (dispatch) => {
    dispatch({
      type: 'USER',
      payload: api.get('/profile/')
    });
  };
}
export function getItem(attribute, id) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ITEM',
      payload: api.get(`${generateURL(attribute)}${id}/`),
      meta: { attribute }
    });
  };
}
export function getInfo(id) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_ITEM_INFO',
      payload: api.get(`/articles/${id}/info/`),
      meta: { attribute: "articleInfo" }
    });
  };
}

export function getHeaderFooter() {
  const attribute = 'config';
  return (dispatch) => {
    dispatch({
      type: "FETCH_CONTENT",
      payload: api.get(`${generateURL(attribute)}`),
      meta: { attribute }
    });
  };
}

export function fetchContent(attribute) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CONTENT",
      payload: api.get(`${generateURL(attribute)}`),
      meta: { attribute }
    });
  };
}

export function fetchContentByPost(attribute, data) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CONTENT",
      payload: api.post(`${generateURL(attribute)}`, data),
      meta: { attribute }
    });
  };
}

export function getContent(url, data, attribute = "page") {
  if (url === "/")
    url = "/home"
  return (dispatch) => {
    dispatch({
      type: 'FETCH_CONTENT',
      payload: api.get(`/${attribute}${url}/`, data),
      meta: { attribute }
    });
  };
}

export function contentInfo(data, { attribute = "articles", url = 'info' }) {
  return (dispatch) => {
    dispatch({
      type: 'FETCH_CONTENT',
      payload: api.post(`/${generateURL(attribute)}${url}/`, data),
      meta: { attribute: get(data, "attr", attribute) }
    });
  };
}

export function getWidgetData(attribute, data) {
  return (dispatch) => {
    dispatch({
      type: "WIDGET",
      payload: api.post(`${generateURL(attribute)}`, data),
      meta: { attribute }
    });
  };
}

export function fetchAdminData(attribute, id, data = {}) {
  return dispatch => {
    dispatch({
      type: "FETCH_CONTENT",
      payload: api.get(`/admin/${attribute}/${id}/`, data),
      meta: { attribute }
    })
  }
}

export function submitReset(attribute) {
  return (dispatch) => {
    dispatch({
      type: 'SUBMIT_RESET',
      payload: {},
      meta: { attribute }
    });
  };
}

export function deleteProfile(attribute, data) {
  return (dispatch) => {
    dispatch({
      type: 'SUBMIT',
      payload: api.delete(`/profile/?deleteOption=${data}`),
      meta: { attribute }
    });
  };
}

export function createMedia(attribute, data) {
  return dispatch => {
    dispatch({
      type: 'SUBMIT',
      payload: api.post('/media/create/', data),
      meta: { attribute }
    })
  }
}

export function setSearch(data) {
  return dispatch => {
    dispatch({
      data,
      type: 'TOGGLE_SEARCH',
    })
  }
}

export function setRegister(data) {
  return dispatch => {
    dispatch({
      data,
      type: 'REGISTER_FORM',
    })
  }
}

export function assignError(data) {
  return dispatch => {
    dispatch({
      data,
      type: 'ASSIGN_ERROR',
    })
  }
}

export function resetState() {
  return dispatch => {
    dispatch({
      type: "RESET_STATE"
    })
  }
}

/** Campaign actions begin */
export function campaignSignup(data) {
  return dispatch => {
    dispatch({
      type: "SUBMIT",
      payload: api.post(`/campaign/sign-up/`, data),
      meta: { attribute: 'campaign', formData: data }
    })
  }
}
/** Campaign actions end */

export function ctapopupSignup(data) {
  return dispatch => {
    dispatch({
      type: "SUBMIT",
      payload: api.post(`/cta-submission/sign-up/`, data),
      meta: { attribute: 'ctapopup', formData: data }
    })
  }
}