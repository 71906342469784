import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';

import style from './style.module.css';
import 'react-phone-number-input/style.css';

const InputPhone = (props) => {
    const { label = false, required, input, placeholder = "Enter Here", serverError = false, fieldClass = false, meta: { error, touched }, boldLabel = false } = props;
    const [value, setValue] = useState(input.value || "");
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setValue(input.value);
    }, [input.value])

    const onChangeHandler = (value) => {
        const { input: { onChange } } = props;
        setValue(value);
        if (!value) {
            onChange("");
        }
        else {
            onChange(value);
        }
    }

    const onBlurHandler = () => {
        setFocus(false);
        const { input: { onChange, onBlur } } = props;
        onBlur(true);
        if (!value) {
            onChange("");
        } else {
            onChange(value);
        }
    }

    return (
        <div className={`${style.formGroup} ${style.inputPhone} ${touched && error ? " " + style.inputError : ""}${boldLabel ? ` ${style.boldLabel}` : ''}`}>
            <div className={`${style.controlLabel} ${style.focus}`}>
                <span>{label}</span>
                {
                    required && (
                        <span className={style.required}>*</span>
                    )
                }
            </div>
            <div className={`${style.formField}${fieldClass ? ` ${fieldClass}` : ''}${touched && error ? " " + style.inputError : ""}${focus ? " " + style.inputFocus : ""}`}>
                <PhoneInput international={true} withCountryCallingCode={true} defaultCountry="GB" placeholder={placeholder} value={value} onChange={onChangeHandler} onFocus={() => setFocus(true)} onBlur={onBlurHandler} limitMaxLength={true} />
                {
                    touched && error && (
                        <p className={style.helpBlock}>{error}</p>
                    )
                }
                {
                    !error && serverError && (
                        <p className={style.helpBlock}>{serverError}</p>
                    )
                }
            </div>
        </div>
    )
}

export default InputPhone;