import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import $ from 'jquery';
import style from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCookie, getCookie, getToken } from '../../helpers/storage';
import { getUser } from '../../actions';
import DialogBox from '../../components/DialogBox';
import { Button } from '../../components/Field';
import { capitalize, get } from 'lodash';
import CookiePopup from '../../components/CookiePopup';

const DarkGuest = () => {
    const [dimesions, setDimensions] = useState({ height: 0, winWidth: window.innerWidth, winHeight: window.innerHeight });
    const token = getToken();
    const cookieData = getCookie("cookie-options", true, {});
    const [showPopup, setPopup] = useState(false);
    const { pathname = "" } = useLocation();
    const dispatch = useDispatch();
    const { fetching = false, status = 0, data = {} } = useSelector((state) => state.storage.user);

    useEffect(() => {
        $("html").removeClass(`lightTheme`).addClass(`darkTheme`);
        function updateDimensions() {
            setDimensions({
                height: $(`.${style.header}`).outerHeight(),
                winWidth: $(window).width(),
                winHeight: $(window).height()
            });
        }
        window.addEventListener('resize', updateDimensions);
        window.addEventListener('scroll', handleHeaderAnimation);
        updateDimensions();
        return () => {
            window.removeEventListener('scroll', handleHeaderAnimation);
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        // toggling cookie popup
        if (get(cookieData, "accept", false) === false) {
            setTimeout(() => {
                setPopup("cookie-popup");
            }, 2000);
        }
    }, []);

    useEffect(() => {
        if (get(cookieData, "accept", false) === true && ["cookie-popup", "manage-preference"].includes(showPopup)) {
            setPopup(false);
        }
    }, [cookieData]);

    useEffect(() => {
        $('html,body').animate({ scrollTop: 0 }, 1000);
    }, [pathname])

    useEffect(() => {
        if (token !== false && token !== "") {
            dispatch(getUser());
        }
    }, [token]);

    const handleHeaderAnimation = () => {
        const top = window.scrollY;
        if (top > 10) {
            $(`.${style.header}`).addClass(`${style.fixedHeader}`);
        } else {
            $(`.${style.header}`).removeClass(`${style.fixedHeader}`);
        }
    }

    useEffect(() => {
        if (fetching === false && status === 200 && token === false) {
            deleteCookie();
        }
        else if (fetching === false && status === 200 && get(data, "isPopuped", false) === true) {
            setPopup("welcome-popup");
        }
    }, [data, fetching, status]);

    return <>
        <div className={`${style.content}`}>
            <Outlet context={{ height: dimesions.height, winWidth: dimesions.winWidth, winHeight: dimesions.winHeight }} />
        </div>
        {
            showPopup === "welcome-popup" && <>
                <DialogBox onClose={() => {
                    setPopup(false)
                }} content={(onCloseHandler) => {
                    return (
                        <div className={style.welcomePopup}>
                            <div className={style.text}>
                                <h4>Hello {capitalize(get(data, "first_name"))}!</h4>
                                <p>Welcome to the SportSmart Hub. Let’s get started</p>
                                <Button onClick={onCloseHandler} label="Get Started" />
                            </div>
                        </div>
                    )
                }}></DialogBox>
            </>
        }
        {
            ["cookie-popup", "manage-preference"].includes(showPopup) && <>
                <DialogBox onClose={() => {
                    setPopup(false)
                }} content={(onCloseHandler) => {
                    return (
                        <CookiePopup setPopup={setPopup} popup={showPopup} onClose={onCloseHandler} />
                    )
                }} />
            </>
        }
    </>
}

export default DarkGuest;