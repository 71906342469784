import { get } from 'lodash';
import { setCookie, deleteCookie, getToken } from '../helpers/storage';

export default function storageReducer(
	state = {
		token: getToken(),
		user: {
			fetching: false,
			data: [],
			fetched: false
		},
		savedList: [],
		search: false,
		registerFormData: { roleId: 3, type: 3, authentication_method: "Phone number" }
	}, action) {
	switch (action.type) {
		case "SAVE_TOKEN": {
			const { data, userType } = action.payload;
			const { meta: { remember } } = action;
			if (userType === 1) {
				setCookie(data, 'token', remember, { path: "/" });
			}
			setCookie(data, 'usertoken', remember, { path: "/" });
			return {
				...state,
				token: userType === 3 ? data : false
			};
		}
		case "SAVE_USER": {
			const { data } = action.payload;
			if (data) {
				return {
					...state,
					user: {
						...state.user,
						data: {
							...data,
							isPopuped: false
						}
					}
				}
			}
			return state
		}
		case "USER_PENDING":
		case "LOGIN_PENDING": {
			return {
				...state,
				user: {
					data: [],
					fetching: true,
					fetched: false
				}
			}
		}
		case "USER_FULFILLED":
		case "LOGIN_FULFILLED": {
			const { status, data } = action.payload
			const { savedList } = data
			let tmpList = []
			if (savedList && savedList.length) {
				savedList.map((sl) => {
					tmpList.push(get(sl, 'articleId'));
					return true;
				})
			}
			return {
				...state,
				user: {
					status,
					data,
					fetching: false,
					fetched: true
				},
				savedList: [...tmpList],
			}
		}
		case "LOGOUT_PENDING":
			return {
				...state,
				user: {
					...state.user,
					logout: {
						fetching: true
					}
				}
			}
		case "LOGOUT_FULFILLED": {
			deleteCookie("usertoken", { path: "/" });
			return {
				...state,
				user: {
					fetching: false,
					data: [],
					fetched: false
				},
				token: false
			};
		}
		case "USER_REJECTED":
		case "LOGIN_REJECTED": {
			const { payload: { response: { status, data } } } = action
			return {
				...state,
				user: {
					status,
					data,
					fetching: false,
					fetched: true
				}
			}
		}
		case "LOGOUT_REJECTED": {
			const { payload: { response: { data } } } = action
			return {
				...state,
				user: {
					...state.user,
					logout: {
						fetching: false,
						status: 200,
						...data
					}
				}
			}
		}
		case "SUBMIT_FULFILLED": {
			const { meta: { attribute }, payload: { data, status } } = action;
			let savedList = state.savedList;
			if (attribute === "save" & status === 200 && get(data, "id")) {
				if (get(data, "status") === true) {
					savedList.push(get(data, "id"));
				}
				if (get(data, "status") === false) {
					savedList = savedList.filter(sl => sl !== get(data, "id"));
				}
			}
			return {
				...state,
				savedList: [...savedList],
			}
		}
		case "TOGGLE_SEARCH": {
			return {
				...state,
				search: get(action,"data",!state.search),
			}
		}
		case "REGISTER_FORM": {
			return {
				...state,
				registerFormData: {
					...state.registerFormData,
					...get(action, "data", {})
				}
			}
		}
		default:
			return state;
	}
}