import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Logo from './Logo';
import Link from '../../components/Link';
import SVG from '../../components/Media/Svg';
import Center from '../../components/Center';
import { generateUrl } from '../../components/Media/Url';

import { getHeaderFooter } from '../../actions';
import TwitterIcon from "../../assets/icons/social-medias/x.svg";
import { darkThemeLogoSvg, lightThemeLogoSvg } from '../../helpers/images';
import { getToken } from '../../helpers/storage';
import CTAPopup from '../../components/CTAPopup';

import style from './style.module.css';


const Footer = () => {
    const dispatch = useDispatch();
    const { pathname = "" } = useLocation();

    useEffect(() => {
        dispatch(getHeaderFooter());
    }, []);

    const { data = {}, fetching = false } = useSelector((state) => get(state, 'content.config', {}));
    const pageData = useSelector((state) => get(state, `content.page.data`, {}));
    let customFooterData = {};
    if (get(pageData, 'widgets', []).length) {
        customFooterData = get(pageData, 'widgets', []).find((w => w.type === "campaign_form"));
    }

    if (pathname === "/authorize") {
        return null
    }

    if (fetching || !get(data, 'footer')) {
        return null;
    }
    const { description = "", address = "", social_links = [], campaign_links = [], links = [], footer_logo_dark, footer_logo_light, squiggle_image, support_image } = get(data, 'footer', {});
    const campaignPages = [`/${process.env.REACT_APP_CAMPAIGN_URL}`, `/${process.env.REACT_APP_PODIUM_CAMPAIGN_URL}`];
    const isCampaign = (campaignPages.indexOf(pathname) !== -1) ? false : false;
    const isPodiumRegister = campaignPages.indexOf(pathname) === 1 ? true : false;
    const icons = {
        "instagram": "fa-brands fa-instagram",
        "twitter": "fa-brands fa-x-twitter",
        "linkedin": "fa-brands fa-linkedin",
        "facebook": "fa-brands fa-facebook",
        "youtube": "fa-brands fa-youtube",
    }
    const isLoggedin = getToken();
    return (
        <>
            <div className={`${style.footer}${isCampaign === true ? ` ${style.campaignPage}` : ""}`}>
                <Center>
                    <div className={style.footerLeft}>
                        {isPodiumRegister &&
                            <div className={style.footerPodiumLogo}>
                                <Link to={get(customFooterData, "campaign_logo_link.url") || "/"} className={style.logo}>
                                    <Logo alt="Podium Analytics" size={`820x0`} dark={darkThemeLogoSvg} light={lightThemeLogoSvg} />
                                </Link>
                            </div>
                        }
                        {!isPodiumRegister &&
                            <Link to="/" className={style.logo}>
                                <Logo size={`320x0`} dark={footer_logo_dark} light={footer_logo_light} />
                            </Link>
                        }
                        <div className={style.footerText}>
                            <img src={generateUrl(squiggle_image, '500x135')} alt="Chart" />
                            <p>{(isPodiumRegister && get(customFooterData, 'footer.text')) ? get(customFooterData, 'footer.text') : description}</p>
                        </div>
                    </div>
                    <div className={style.footerRight}>
                        {
                            links.map((item, i) => {
                                if (campaignPages.indexOf(pathname) !== -1 && get(item, 'title', '').toLowerCase() === 'sitemap') {
                                    return null;
                                } else {
                                    return (<div className={style.footerCol} key={`f_title_${i}`}>
                                        <div className={style.footerTitle}>{item.title}</div>
                                        {
                                            item.links.map((linkItem, j) => {
                                                const show_in_public = get(linkItem, 'show_in_public', 1);
                                                if (show_in_public === 0 && !isLoggedin) {
                                                    return null;
                                                }
                                                return (
                                                    <div className={style.footerLinkItem} key={`f_title_${i}_${j}`}>
                                                        <Link target={get(linkItem, "target", "_self")} to={get(linkItem, "url", '#')}>{linkItem.label}</Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>)
                                }
                            })
                        }
                    </div>
                    <div style={{ clear: "both" }}></div>
                    {
                        isCampaign === true && (
                            <div className={`${style.footerLeft} ${style.support_img}`}>
                                <img className={style.supportImage} alt={get(support_image, "Support")} src={generateUrl(support_image, "400x0")} />
                            </div>
                        )
                    }
                    <div className={`${style.footerRight} ${style.block}`}>
                        <div className={`${style.socials}`}>
                            {
                                isCampaign === false && social_links.map((item, i) => {
                                    return (
                                        <div className={style.socialItem} key={`f_s_${i}`}>
                                            <Link to={get(item, 'link')}>
                                                <i className={icons[get(item, 'icon')]}></i>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                            {
                                isCampaign === true && campaign_links.map((item, i) => {
                                    return (
                                        <div className={style.socialItem} key={`f_c_s_${i}`}>
                                            <Link to={get(item, 'link')}>
                                                {item.icon === "twitter" ?
                                                    <SVG src={TwitterIcon} /> :
                                                    <i className={icons[get(item, 'icon')]}></i>
                                                }
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            isCampaign === true && !isEmpty(address) && (
                                <p className={style.companyRegistration} dangerouslySetInnerHTML={{ __html: address }} />
                            )
                        }
                    </div>

                    {
                        isCampaign === false && (
                            <>
                                {!isEmpty(address) && <p className={style.companyRegistration} dangerouslySetInnerHTML={{ __html: address }} />}
                                {!isPodiumRegister &&
                                    <div className={style.podiumLogo}>
                                        <Link to={process.env.REACT_APP_PODIUM_SITE} className={style.logo}>
                                            <Logo alt="Podium Analytics" size={`820x0`} dark={darkThemeLogoSvg} light={lightThemeLogoSvg} />
                                        </Link>
                                    </div>
                                }
                            </>
                        )
                    }
                </Center>

            </div>
            <CTAPopup />
        </>
    )
}

export default Footer;