import { combineReducers } from "redux";
import { reducer as forms } from 'redux-form';

import submitReducer from './submitReducer';
import storageReducer from './storageReducer';
import contentReducer from './contentReducer';
import widgetReducer from './widgetReducer';
import itemReducer from "./itemReducer";

const appReducer = combineReducers({
    form: forms,
    submit: submitReducer,
    storage: storageReducer,
    content: contentReducer,
    widget: widgetReducer,
    items: itemReducer
});
// RESET_STATE

const rootReducer = (state, action) => {
    if (action.type === 'RESET_STATE') {
        state = {}
    }
    return appReducer(state, action)
}

export default rootReducer;