import React from 'react';
import { generateUrl } from '../../components/Media/Url';

const Logo = ({ dark, light, size = "320x0", alt = "SportSmart Hub Logo" }) => {
    return (
        <>
            <img src={generateUrl(dark, size)} alt={alt} data-theme="dark" />
            <img src={generateUrl(light, size)} alt={alt} data-theme="light" />
        </>
    )
}

export default Logo;