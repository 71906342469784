import { addValidator, format } from 'redux-form-validators';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const phoneValidator = addValidator({
	defaultMessage: "Invalid Phone Number",
	validator: function (options, value = "", allValues) {
		if(value === ""){
			return true
		}
		return value ? isValidPhoneNumber(value.toString(), "GB") : false
	}
})

export const emailValidatorRegex = () => {
	// eslint-disable-next-line no-control-regex
    return [format({ with: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g, message: 'Invalid email' })];
}