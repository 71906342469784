import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery-slim';
import { get } from 'lodash';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';

import { setSearch } from "../../actions/index";

import Media from "../../components/Media";
import Link from '../../components/Link';
import Logo from './Logo';
import { Search, Chevron } from '../../helpers/Icons';
import { Button } from '../../components/Field';
import { getToken } from '../../helpers/storage';
import { darkThemeLogoSvg, lightThemeLogoSvg } from '../../helpers/images';

import style from './style.module.css';
import Placeholder from '../../components/Placeholder';
import Center from '../../components/Center';

const Header = () => {
    const [height, setHeight] = useState(0);
    const [popup, setPopup] = useState(false);
    const dispatch = useDispatch();
    const [mobileMenu, setMobilemenu] = useState(false);
    const [clickedMenu, setClickMenu] = useState(false);
    const isLoggedin = getToken();
    const { user: { data } } = useSelector(state => state.storage);
    const { fetching = false, status } = useSelector((state) => get(state, 'content.config', {}));
    const { links, header_logo_dark = "", header_logo_light = "", button = {} } = useSelector((state) => get(state, 'content.config.data.header', {}));
    const { pathname = "" } = useLocation();
    let bhTimeout = null;
    const campaignPages = [`/${process.env.REACT_APP_CAMPAIGN_URL}`, `/${process.env.REACT_APP_PODIUM_CAMPAIGN_URL}`];
    const isCampaign = (campaignPages.indexOf(pathname) !== -1) ? true : false;
    const pageState = useSelector((state) => get(state, `content.page`, {}));
    const pageStatus = get(pageState, 'status');
    const pageData = get(pageState, 'data', {});
    let customHeaderData = {};
    if (get(pageData, 'widgets', []).length) {
        customHeaderData = get(pageData, 'widgets', []).find((w => w.type === "campaign_form"));
    }
    const isPodiumRegister = campaignPages.indexOf(pathname) === 1 ? true : false;

    const updateHeight = () => {
        setHeight($(`.${style.header}`).outerHeight());
    }

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        updateHeight();
        return () => {
            window.removeEventListener('resize', updateHeight);
        }
    }, [fetching]);

    useEffect(() => {
        //This is needed, because for campaign pages, we are changing the logos
        if (status === 200 && isCampaign) {
            setTimeout(() => {
                updateHeight();
            }, 100);
        }
    }, [status, isCampaign, pageStatus])

    useEffect(() => {
        if (popup) {
            $('html').addClass('overflow-hidden');
        } else {
            $('html').removeClass('overflow-hidden');
        }

        if (popup) {
            bhTimeout = setTimeout(() => {
                $('html').addClass('black-header');
            }, 801);
        } else {
            $('html').removeClass('black-header');
        }

        return () => {
            clearTimeout(bhTimeout);
        }
    }, [popup])

    const toggleMobilemenu = (toggle = false) => {
        if (toggle === false) {
            $(`.${style.mobileMenu}`).removeClass(style.show)
            $('html').removeClass('overflow-hidden');
            setTimeout(() => {
                setMobilemenu(false);
            }, 1000);
        } else {
            setMobilemenu(true);
            setTimeout(() => {
                $(`.${style.mobileMenu}`).addClass(style.show)
                $('html').addClass('overflow-hidden');

            }, 100);
        }
    }

    useEffect(() => {
        setPopup(false);
        dispatch(setSearch(false));
        toggleMobilemenu(false);
        setClickMenu(false);
    }, [pathname]);

    const getSubMenuHtml = (submenu) => {
        let html = null;
        let isAnySubmenuActive = false;
        if (submenu && submenu.length > 0) {
            html = (<div className={style.submenu}>
                {submenu.map((sItem) => {
                    const subLink = get(sItem, 'url', '');
                    const submenuActive = window.location.pathname.replace(process.env.REACT_APP_URL_PREFIX, '') === subLink;
                    if (submenuActive && isAnySubmenuActive === false) {
                        isAnySubmenuActive = true;
                    }
                    const show_in_public = get(sItem, 'show_in_public', 1);
                    if (show_in_public === 0 && !isLoggedin) {
                        return null;
                    }
                    return (
                        <Link key={subLink} to={subLink} target={get(sItem, 'target')} className={`${style.submenuItem}${submenuActive ? ` ${style.active}` : ``}`}><span>{get(sItem, 'label')}</span></Link>
                    )
                })}
            </div>
            )
        }
        return [html, isAnySubmenuActive];
    }

    const NavLink = () => {
        return <>
            {links && links.length > 0 && links.map(({ url = "#", label = "", target = "_self", submenu = [], show_in_public = 1 }, l) => {
                const trimmedPathname = pathname.split("/")[1] || "home";
                const trimmedLink = url.replaceAll("/", "");
                if (url === "#" || url === null || url === false) {
                    return null;
                }

                let submenuHtml = getSubMenuHtml(submenu);
                let isAnySubmenuActive = submenuHtml[1];
                const hasSubmenu = submenu && submenu.length > 0;
                if (show_in_public === 0 && !isLoggedin) {
                    return null;
                }
                return (
                    <div className={`link-item-${l + 1} ${style.linkItem}${hasSubmenu ? ` ${style.hasSubmenu}` : ``}`} key={l}>
                        <Link to={url} target={target} className={`${style.link}${trimmedPathname === trimmedLink || isAnySubmenuActive ? ` ${style.active}` : ``}`}>{label}</Link>
                        {submenu && submenu.length > 0 &&
                            <Link className={style.chevronArrow}
                                onClick={() => {
                                    if ($(`.link-item-${l + 1}`).hasClass(style.showSubMenu)) {
                                        $(`.link-item-${l + 1}`).removeClass(style.showSubMenu);
                                    } else {
                                        $(`.link-item-${l + 1}`).addClass(style.showSubMenu);
                                    }

                                }}
                            >
                                <ReactSVG src={Chevron} />
                            </Link>
                        }
                        {submenuHtml[0]}
                    </div>
                )
            })}
        </>
    }

    const { profile = null, first_name = "" } = data;

    if (pathname === "/authorize" || pathname === "/login") {
        return null
    }

    const SearchBtn = () => {
        return (
            <Link className={`${style.search}`} onClick={() => { dispatch(setSearch()) }}>
                <ReactSVG src={Search} />
            </Link>
        );
    }

    const isRegister = pathname === "/register";
    return (
        <>
            <div className={`${isRegister ? `${style.register} ` : ''}header ${style.header}${(mobileMenu && !isRegister) ? ` ${style.blackBar}` : ''}${isCampaign ? ` ${style.campaign}` : ""}`}>
                <Center>
                    <div className={style.headerCenter}>
                        {
                            fetching === true && <div className={style.headerLoader}>
                                <div className={style.logo}>
                                    <Placeholder type="logo" />
                                </div>
                                <div className={style.menus}>
                                    <Placeholder type="title" />
                                    <Placeholder type="title" />
                                    <Placeholder type="title" />
                                    <Placeholder type="title" />
                                </div>
                                <div className={(isLoggedin === false || isLoggedin === "") ? style.button : style.profile}>
                                    <Placeholder />
                                </div>
                            </div>
                        }
                        {
                            fetching === false && <>
                                {
                                    (
                                        <Link onClick={() => { toggleMobilemenu(!mobileMenu); setClickMenu(!clickedMenu) }} className={`${style.hamburgerMenu}${clickedMenu ? ` ${style.toggled}` : ''}`}>
                                            <div></div>
                                        </Link>
                                    )
                                }
                                <Link to={isCampaign ? (get(customHeaderData, "campaign_logo_link.url", '#')) : "/"} className={style.logo} onClick={() => { setPopup(false) }}>
                                    {isPodiumRegister && <div className={style.podiumHeaderLogo}><Logo alt="Podium Analytics" size={`820x0`} dark={darkThemeLogoSvg} light={lightThemeLogoSvg} /></div>}
                                    {!isPodiumRegister && <Logo light={header_logo_light} dark={header_logo_dark} />}
                                </Link>
                                {
                                    isCampaign === false && (
                                        <div className={`${style.headerMenu}`}>
                                            <NavLink />
                                        </div>
                                    )
                                }
                                <div className={`${style.signInButton}`}>
                                    {
                                        isCampaign === true && get(customHeaderData, "campaign_button_link.label") && (<Button color="transparent" link={get(customHeaderData, "campaign_button_link.url", '#')} label={get(customHeaderData, "campaign_button_link.label", "Sign In")} />)
                                    }
                                    {
                                        !isLoggedin && isCampaign === false && (
                                            <>
                                                <SearchBtn />
                                                <Button link="/register" label="Register" color="whiteBordered" className={style.headerBtn} />
                                                <Button httpTarget={'_self'} link={get(button, "url", '#')} label={get(button, "label", "Sign In")} className={style.headerBtn} />
                                            </>
                                        )
                                    }
                                    {
                                        isLoggedin && isCampaign === false && (
                                            <div className={`${style.profileSection}`}>
                                                <SearchBtn />
                                                <Link className={`${style.icon}`} to={`/profile`}>
                                                    {
                                                        profile === null && first_name !== null ?
                                                            <div className={style.letter}>{first_name.substring(1, 0)}</div> :
                                                            <Media brokenIcon={`fa-user`} src={profile} size="96x96" type="image" lazy={false} />
                                                    }
                                                </Link>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={style.clearClass}></div>
                            </>
                        }
                    </div>
                </Center>
            </div>
            <div className={style.heightBar} style={{ height }}></div>
            {
                mobileMenu === true && <div className={style.mobileMenu} style={{ top: `${height}px`, height: `calc(100% - ${height}px)` }}>
                    <div className={style.container}>
                        <div className={style.items}>
                            <NavLink />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Header;