import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import Media from '../Media';
import Link from '../Link';
import SVG from '../Media/Svg';
import Form from './Form';
import { Close, CaretDown, CaretUp } from '../../helpers/Icons';
import { Button } from '../Field';
import { getCookie, setCookie } from '../../helpers/storage';

import style from './style.module.css';

let closeTimeout;
let openTimeout;
let scrollTimeout;
const CTAPopup = () => {
    const pathname = window.location.pathname;
    const [step, setStep] = useState("");
    const [path, setPath] = useState(pathname);
    const [mounted, setMounted] = useState(false);
    const [popupClass, setPopupClass] = useState("");
    const [pd, setPD] = useState({});
    const [canOpen, setCanOpen] = useState(false);
    const [windowY, setWindowY] = useState(window.scrollY);
    const [openedStatus, setOpenedStatus] = useState(false);
    const OpenRef = useRef(canOpen);
    let pageData = useSelector((state) => get(state, `content.page.data`, {}));
    let articleData = useSelector((state) => get(state, `items.articles.data`, {}));
    if (path.includes('/articles/') && get(articleData, 'id')) {
        pageData = articleData;
    }
    const popup = get(pageData, 'popup', '');
    const { initial, sign_up, success, layout, bg_color = "black", id, type_of_popup } = get(pd, 'popupData', {});
    const pageId = get(pageData, 'id');
    const introSectionType = get(initial, 'img.id') ? 'image' : 'text';
    const successSectionType = get(success, 'img.id') ? 'image' : 'text';
    const popupCookie = getCookie("ctapopup", true, {});

    const openPopup = () => {
        if (get(popupCookie, `${pageId}`) !== 1) {
            setPopupClass(style.active);
            setStep("intro");
            setCanOpen(false);
            setOpenedStatus("opened");
        }
    }

    const closePopup = (force) => {
        setWindowY(0);
        setCanOpen(false);
        setPopupClass("");
        setOpenedStatus("hided");
        setTimeout(() => {
            if (force) {
                setOpenedStatus("closed");
                setCookie({ ...popupCookie, [pageId]: 1 }, "ctapopup", false);
            }
        }, 401);
    }

    const clearTimetouts = () => {
        clearTimeout(closeTimeout);
        clearTimeout(openTimeout);
        clearTimeout(scrollTimeout);
    }

    useEffect(() => {
        OpenRef.current = canOpen;
        if (canOpen) {
            if (windowY >= 250) {
                clearTimeout(openTimeout);
                openPopup();
            } else {
                openTimeout = setTimeout(() => {
                    openPopup();
                }, 5000)
            }
        }
    }, [canOpen, windowY]);

    useEffect(() => {
        const scrolled = () => {
            if (OpenRef.current) {
                clearTimeout(scrollTimeout);
                scrollTimeout = setTimeout(() => {
                    setWindowY(window.scrollY);
                }, 300)
            }
        }
        window.addEventListener('scroll', scrolled);
        return (() => {
            clearTimetouts();
            window.removeEventListener('scroll', scrolled);
        })
    }, []);

    useEffect(() => {
        if (popup && mounted) {
            setCanOpen(true);
            setWindowY(0);
        }
        if (popup) {
            setMounted(true);
            setPD(pageData);
        }
    }, [popup, mounted]);

    useEffect(() => {
        if (path) {
            clearTimetouts();
            closePopup();
        }
    }, [path])

    useEffect(() => {
        setPath(pathname);
    }, [pathname]);

    const TextWrap = ({ title, text, infoText, buttonText, onClick, link }) => {
        return (
            <div className={style.textWrap}>
                {title && <div className={style.title}>{title}</div>}
                {text && <div className={style.text} dangerouslySetInnerHTML={{ __html: text }}></div>}
                {buttonText &&
                    <div className={style.action}>
                        <Button label={buttonText} onClick={onClick} link={link} />
                    </div>
                }
                {infoText && <div className={style.infoText} dangerouslySetInnerHTML={{ __html: infoText }}></div>}
            </div>
        );
    }

    const IntroWithImage = ({ title, text, image, infoText, buttonText, onClick, link }) => {
        return (
            <div className={`${style.section} ${style[layout]}`}>
                <div className={style.withImage}>
                    <div className={style.imageWrap}>
                        <Media src={image} size="500x0" lazy={false} />
                    </div>
                    <TextWrap title={title} text={text} infoText={infoText} buttonText={buttonText} onClick={onClick} link={link} />
                </div>
            </div>
        )
    }

    const Intro = ({ title, text, infoText, buttonText, onClick, link }) => {
        return (
            <div className={`${style.section} ${style[layout]}`}>
                <div className={style.withOutImage}>
                    <TextWrap title={title} text={text} infoText={infoText} buttonText={buttonText} onClick={onClick} link={link} />
                </div>
            </div>
        )
    }

    return (
        <div className={`${style.ctapopup} ${style[bg_color]}${popupClass ? ` ${popupClass}` : ``}`}>
            <div className={`${style.closeActions}${(!popup || get(popupCookie, `${pageId}`) === 1 || openedStatus === "closed") ? ` ${style.hide}` : ``}`}>
                {openedStatus === "hided" &&
                    <Link className={style.close} onClick={() => { clearTimetouts(); openPopup(); }}><span>Open</span><span className={style.icon}><CaretUp /></span></Link>
                }
                {openedStatus === "opened" &&
                    <Link className={style.close} onClick={() => { clearTimetouts(); closePopup(); }}><span>Hide</span><span className={style.icon}><CaretDown /></span></Link>
                }
                <Link className={style.close} onClick={() => { clearTimetouts(); closePopup(true) }}><span>Close</span><span className={style.icon}><SVG src={Close} /></span></Link>
            </div>
            <div className={style.wrap}>
                {step === "intro" &&
                    <>
                        {introSectionType === 'text' &&
                            <>
                                {type_of_popup === "signup_form" &&
                                    <Intro
                                        buttonText={get(initial, 'btn_label')}
                                        title={get(initial, 'title')}
                                        text={get(initial, 'text')}
                                        onClick={() => setStep('form')}
                                    />
                                }
                                {type_of_popup === "cta_button" &&
                                    <Intro
                                        buttonText={get(initial, 'btn_label')}
                                        title={get(initial, 'title')}
                                        text={get(initial, 'text')}
                                        link={get(initial, 'btn_url.url')}
                                    />
                                }
                            </>
                        }
                        {introSectionType === "image" &&
                            <>
                                {type_of_popup === "signup_form" &&
                                    <IntroWithImage
                                        buttonText={get(initial, 'btn_label')}
                                        title={get(initial, 'title')}
                                        text={get(initial, 'text')}
                                        image={get(initial, 'img', {})}
                                        onClick={() => setStep('form')}
                                    />
                                }
                                {type_of_popup === "cta_button" &&
                                    <IntroWithImage
                                        buttonText={get(initial, 'btn_label')}
                                        title={get(initial, 'title')}
                                        text={get(initial, 'text')}
                                        image={get(initial, 'img', {})}
                                        link={get(initial, 'btn_url.url')}
                                    />
                                }
                            </>
                        }
                    </>
                }
                {step === "form" &&
                    <div className={`${style.section} ${style.form} ${style[layout]}`}>
                        <TextWrap
                            title={get(sign_up, 'title')}
                            text={get(sign_up, 'text')}
                        />
                        <Form
                            bottomText={get(sign_up, 'bottom_text')}
                            privacyText={get(sign_up, 'privacy_link')}
                            changeStep={(step) => setStep(step)}
                            id={id}
                        />
                    </div>
                }
                {step === "thank-you" &&
                    <>
                        {successSectionType === 'text' &&
                            <Intro
                                infoText={get(success, 'bottom_text')}
                                buttonText={get(success, 'btn_label')}
                                title={get(success, 'title')}
                                text={get(success, 'text')}
                                onClick={() => closePopup(true)}
                            />
                        }
                        {successSectionType === "image" &&
                            <IntroWithImage
                                infoText={get(success, 'bottom_text')}
                                buttonText={get(success, 'btn_label')}
                                title={get(success, 'title')}
                                text={get(success, 'text')}
                                image={get(success, 'img', {})}
                                onClick={() => closePopup(true)}
                            />
                        }
                    </>
                }
            </div>
        </div>
    )
}
export default CTAPopup;