import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { get } from "lodash";

import Link from "../Link";
import { Button } from "../Field";
import Item from "./Item";

import { setCookie, getCookie } from "../../helpers/storage";

import style from "./style.module.css";

const options = [
    {
        name: "non-essential-cookies",
        title: "Accept non-essential cookies only",
        desc: "Our website needs these cookies to work properly, so we can’t switch them off. They let us save your privacy settings, signup progress, and remember things like your dog's name.",
    },
    {
        name: "all-cookies",
        title: "Accept all cookies",
        desc: "We use cookies to enhance your browsing experience and gather valuable insights to improve our services. Our website currently employs a simplified approach – by clicking \"Accept all cookies\", you're allowing us to utilise cookies that optimise site functionality, analyse traffic patterns, and personalise content to better suit your preferences."
    },
    {
        name: "reject-cookies",
        title: "Reject cookies",
        desc: "If you choose to reject all cookies, we won’t store any data on your device, except for essential cookies necessary for the website's basic functionality."
    }
];

function CookiePopup(props) {

    const { onClose, popup, setPopup, handleSubmit, submitHandler, savePreference, formValues } = props;
    const [activeOption, setActiveOption] = useState(-1);

    const handleCookieAccept = (accept = true) => {
        savePreference({
            ...formValues,
            accept
        });
        onClose();
    };

    const handleManage = () => {
        setPopup(false);
        setTimeout(() => {
            setPopup("manage-preference");
        }, 200);
    }

    const btnLabel = ()=>{
        if(get(formValues,"option","") === "reject-cookies"){
            return `Reject Cookies`;
        }

        const isNonEssentialCookies = get(formValues,"option", "") === "all-cookies";

        return `Accept${["manage-preference"].includes(popup) ? ` ${isNonEssentialCookies ? "all" : ""}` : ""} cookies`;
    }

    return (<>
        <div className={`${style.cookiePopup}${style[popup] ? ` ${style[popup]}` : ""}`}>
            <div className={`${style.wrapper}`}>
                {
                    popup === "cookie-popup" && <>
                        <h3 className={style.title}>Cookie Settings</h3>
                        <div className={style.text}>
                            <p>
                                We use cookies on the SportSmart Hub to give our users the best possible experience, and to help us understand how visitors are using the
                                site.
                            </p>
                            <Link className={style.link} to="https://podiumanalytics.org/policies/cookies/">Read our full Cookies Policy here.</Link>
                        </div>
                    </>
                }
                {
                    popup === "manage-preference" && <>
                        <h3 className={style.title}>Manage Preferences</h3>
                        <div className={style.content}>
                            <h6 className={style.subTitle}>Your preferences for cookies</h6>
                            <p className={style.text}>
                                The options provided in this section allow you to customise your preferences for any cookies used on the SportSmart Hub.
                                To learn more about how these cookies work, refer to the <Link className={style.link} to={`https://podiumanalytics.org/policies/cookies/`}>cookie policy</Link>.
                            </p>
                            <form name="cookie-preference-form" className={style.dropdowns} onSubmit={handleSubmit(submitHandler)}>
                                <ul className={style.items}>
                                    {
                                        options.map((value, index) => {
                                            return (<Item key={`cookie_option_${value.name}`} {...value}
                                                isActive={activeOption === index ? true : false}
                                                onClick={() => { setActiveOption(index === activeOption ? -1 : index) }}
                                            />)
                                        })
                                    }
                                </ul>
                            </form>
                        </div>
                    </>
                }
                <div className={style.buttons}>
                    <Button className={style.accept} onClick={() => { handleCookieAccept(true) }} label={btnLabel()} />
                    {
                        popup === "cookie-popup" && <Button className={style.manage} onClick={handleManage} label="Manage preferences" />
                    }
                    {
                        popup === "manage-preference" && <Button className={style.manage} onClick={() => { handleCookieAccept(false) }} label="Save my preferences" />
                    }
                </div>
            </div>
        </div>
    </>)
}

const mapStateToProps = (state) => {
    return {
        formValues: get(state, "form.cookie-preference-form.values", {}),
        initialValues: {
            ...getCookie("cookie-options", true, {})
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitHandler: (data) => {
            console.log(data);
        },
        savePreference: (data) => {
            setCookie(JSON.stringify({ ...data }), "cookie-options");
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'cookie-preference-form',
    enableReinitialize: true
})(CookiePopup));